import {Injectable} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Router} from '@angular/router';

declare var $;
@Injectable()
export class HttpRequestService {
  constructor(
    private http: HttpClient,
    private router: Router,
  ) {}

  public checkError(error) {
    if (error.status == 401 && (
      error.error.error_message === 'Signature verification failed' ||
      error.error.error_message === 'Something is wrong with token validation')) {
      localStorage.clear();
      window.location.href = environment.ladingUrl;
    }  else if ((error.status == 403 && error.error.error_message === 'Url expired') ||
      (error.status == 409 && error.error.error_message === 'The questionnaire has already been completed')) {
      localStorage.clear();
      this.router.navigate(['/access-denied']);
    } else if (error.status == 401 && error.error.error_message === 'Invalid credentials') {
      $('.httpError').html(error.error.error_message);
    } else if (error.status == 401) {
      localStorage.clear();
      this.router.navigate(['/login']);
    } else {
      if (error.error) {
        $('.httpError').html(error.error.error_message);
      }
    }
  }

  public url(path: string): string {
    return environment.services.apiUrl + path;
  }

  public get(path, isFile= false): Observable<any> {
    $('.httpError').html('');
    const options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        'refresh': 'Bearer ' + localStorage.getItem('refreshToken')
      })
    };

    if (isFile) {
      options['responseType'] = 'blob' as 'json';
    }

    return this.http.get<Observable<any>>(this.url(path), options)
      .pipe(
        map( data => {
          return data;
        }),
        catchError( error => {
          this.checkError(error);
          return throwError(error);
        })
      );
  }

  public post(path, parameters, comesFromForm = true, file?): Observable<any> {
    let headers;
    const tmp = {};
    $('.httpError').html('');

    headers = {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        'refresh': 'Bearer ' + localStorage.getItem('refreshToken')
      })
    };

    if (file) {
      const formData = new FormData();
      headers = {
        headers: new HttpHeaders({
          'enctype': 'multipart/form-data',
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          'refresh': 'Bearer ' + localStorage.getItem('refreshToken')
        })
      };
      Object.keys(parameters).forEach(function(k) {
        formData.append(k, parameters[k]);
      });
      parameters = formData;
      parameters.append('file', file, file.name);
    } else if (comesFromForm) {
      if (typeof parameters != 'string') {
        Object.keys(parameters).forEach(function (k) {
          tmp[k] = parameters[k].value;
        });
        parameters = JSON.stringify(tmp);
      }
    }

    return this.http.post<Observable<any>>(this.url(path), parameters, headers)
      .pipe(
        map(data => {
          return data;
        }),
        catchError(error => {
          this.checkError(error);
          return throwError(error);
        })
      );
  }

  public patch(path, parameters, comesFromForm = true, file?): Observable<any> {
    let headers;
    const tmp = {};
    $('.httpError').html('');

    headers = {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        'refresh': 'Bearer ' + localStorage.getItem('refreshToken')
      })
    };

    if (file) {
      const formData = new FormData();
      headers = {
        headers: new HttpHeaders({
          'enctype': 'multipart/form-data',
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          'refresh': 'Bearer ' + localStorage.getItem('refreshToken')
        })
      };
      Object.keys(parameters).forEach(function(k) {
        formData.append(k, parameters[k]);
      });
      parameters = formData;
      parameters.append('file', file, file.name);
    } else if (comesFromForm) {
      if (typeof parameters != 'string') {
        Object.keys(parameters).forEach(function (k) {
          tmp[k] = parameters[k].value;
        });
        parameters = JSON.stringify(tmp);
      }
    }

    return this.http.patch<Observable<any>>(this.url(path), parameters, headers)
      .pipe(
        map(data => {
          return data;
        }),
        catchError(error => {
          this.checkError(error);
          return throwError(error);
        })
      );
  }
}