import {NgModule} from '@angular/core';
import {LayoutComponent} from './page/layout.component';
import {LayoutRoutingModule} from './layout-routing.module';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [
    LayoutComponent,
  ],
    imports: [
        LayoutRoutingModule,
        CommonModule,
    ]
})

export class LayoutModule {}
