import {environment} from '../../environments/environment';

const backendUrl = environment.services.apiUrl;
export const Constants = {
  endpoint: {
    questions: backendUrl + 'questions/',
    typeform: backendUrl + 'webhook/typeform',
    report: backendUrl + 'report/activity'
  }

};