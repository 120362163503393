import {Injectable} from '@angular/core';
import {AbstractControl, ValidatorFn} from '@angular/forms';

@Injectable()
export class CustomValidatorService {
  arePasswordEquals(key: string): ValidatorFn {
      return (control: AbstractControl): { [key: string]: boolean } | null => {
        let compare: string;

        if (control.root.get(key)) {
          compare = control.root.get(key).value;
        }

        if (control.value !== compare) {
          return { passwordsNotEquals: true };
        }

        return null;
      };
  }
}

