import { Component } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent  {
  names = localStorage.getItem('username');
  photo = localStorage.getItem('photo');
  role = localStorage.getItem('pem');
  showMenu: boolean;

  constructor(
    private router: Router,
  ) {}



  singOut() {
    const self = this;
    localStorage.clear();
    self.router.navigate(['/login']);

  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }
}
