import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {LayoutModule} from './components/layout/layout.module';
import {UtilsService} from './core/services/utils.service';
import {HttpRequestService} from './core/services/http-request.service';
import {CustomValidatorService} from './core/services/custom-validator.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { environment } from 'src/environments/environment';
import { ModalQuestionnaireComponent } from './components/questionnaire/modal/modal-questionnaire.component';
//import { CountriesComponent } from './components/countries/countries.component';
import { DirectivesModule } from './shared/directive/directive.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@NgModule({
  declarations: [
    AppComponent,
    ModalQuestionnaireComponent,
    //CountriesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    LayoutModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    DirectivesModule,
    ProgressSpinnerModule
  ],
  providers: [
    UtilsService,
    HttpRequestService,
    CustomValidatorService,
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
