import {Injectable} from '@angular/core';
import {Constants} from '../constants';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import * as uuid from 'uuid';
import {environment} from '../../../environments/environment';
import {catchError, Subscription } from 'rxjs';
import {HttpRequestService} from './http-request.service';
import {AngularFireAuth} from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class RpsService {
  token: any;

  constructor(private httpClient: HttpClient,
              private http: HttpRequestService,
              private angularFireAuth: AngularFireAuth) {
  }


  getAllQuestions(token) {
    return this.httpClient.get(Constants.endpoint.questions, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
          'refresh': 'Bearer ' + localStorage.getItem('refreshToken')
        })
    }).pipe(catchError(error => {
      this.http.checkError(error);
      throw new Error(error);
    }));
  }

  sendResults(answers: any[], origin, token) {
    const id = uuid.v4();
    const body = {
      'event_id': id,
      'event_type': 'form_response',
      'origin': origin + this.getBrowser(),
      form_response: {form_id: id, answers}

    };

    const headers = new HttpHeaders(
      {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Typeform-Signature': 'sha256=AwQd8MN7/z6TR8naDaDrq6yIRLUhjRAw2nAAGxpTwXw=',
        'refresh': 'Bearer ' + localStorage.getItem('refreshToken')
      });

    return this.httpClient.post(Constants.endpoint.typeform, body,
      {
        headers
      }).pipe(catchError(error => {
      this.http.checkError(error);
      throw new Error(error);
    }));
  }

  getCountries() {
    return this.httpClient.get(environment.services.restCountries);
  }

  /**
   * signupAnonymo()
   * Sign up the user
   * @return the anonymous user's session token
   * @memberof RpsService
   */
  signupAnonymo(): Promise<string> {

    let subscription = new Subscription();
    return new Promise((resolve, reject) => {
      this.angularFireAuth.signInAnonymously().then(() => {
        subscription = this.angularFireAuth.idToken.subscribe((tokenId: string) => {
          /**
           * return user's session token
           */
          this.token = tokenId;
          resolve(tokenId);
          subscription.unsubscribe();
        });
      }).catch(err => {
        reject(err);
        subscription.unsubscribe();
      });
    });
  }

  /**
   * deletenAnonymously()
   * @returns {Promise<void>} anonymous user delete confirmation
   * @memberof RpsService
   */

  deletenAnonymously(): Promise<string> {
    const subscription = new Subscription();
    return new Promise((resolve, reject) => {
      this.angularFireAuth.currentUser.then(user => {
        /**
         * return user instance
         */
        if (user) {
          /**
           * delete anonymous user
           */
          user.delete();
          /**
           * clear local storage
           */
          localStorage.clear();
        }
      }).catch(err => {
        reject(err);
        subscription.unsubscribe();
      });
    });
  }

  getBrowser() {
    try {
      return ' ' + this.detectBrowserName() + ' - ' + this.detectBrowserVersion();
    } catch (e) {
      console.warn(e);
      return '';
    }
  }

  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }


  detectBrowserVersion() {
    let userAgent = navigator.userAgent, tem,
      matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(matchTest[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return 'IE ' + (tem[1] || '');
    }

    if (matchTest[1] === 'Chrome') {
      tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) {
        return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
    }

    matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = userAgent.match(/version\/(\d+)/i)) != null) {
      matchTest.splice(1, 1, tem[1]);
    }
    return matchTest.join(' ');
  }
}
